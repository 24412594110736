<template>
  <div id='details_news' v-loading='loading'>
    <h2>{{ newsData.title }}</h2>
    <div class='item-news-s'>
      <span>{{ $t('news.time') }}：{{ newsData.create_time || '--' }}</span>
      <el-divider direction='vertical'></el-divider>
      <span>{{ $t('news.anther') }}</span>
      <!--      <el-divider direction="vertical"></el-divider>-->
    </div>
    <div id='image_html' ref='htmls' v-html='contenss'></div>
  </div>
</template>

<script>
import { showNews } from '@/api/banner'

export default {
  name: 'newsDetails',
  data() {
    return {
      newsData: {},
      contenss: '',
      loading: false
    }
  },
  mounted() {
    this.get_showNews()
  },
  methods: {
    get_showNews: function() {
      showNews({ id: this.$route.query.id }).then(resp => {
        if (resp.status == 200) {
          resp.data.create_time = this.formatDate(resp.data.create_time)
          this.$set(this, 'newsData', resp.data)
          this.$set(this, 'contenss', resp.data.content)
        }
      }).catch(err => {
      })
    },
    formatDate(item_date) {
      let date = new Date(parseInt(item_date) * 1000)
      let YY = date.getFullYear() + '-'
      let MM =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return YY + MM + DD
    }
  }
}
</script>

<style scoped lang='scss'>
#details_news {
  background-color: #fff;
  width: 60%;
  padding: 15px;
  margin: auto;
  font-size: 16px;
  font-family: PingFang SC;
  line-height: 35px;
  min-height: 400px;

  h2 {
    color: #333;
    font-family: PingFang SC;
    font-weight: bold;
    text-align: center;
  }

  .item-news-s {
    font-weight: 500;
    color: #999999;
    text-align: center;
    line-height: 50px;
  }
}
</style>
